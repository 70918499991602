.register {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.register__container {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgrey;
    padding: 20px;
}

.register__container>h1 {
    font-weight: 500;
    margin-bottom: 20px;
}

.register__container>form>h5 {
    margin-bottom: 5px;
}

.register__container>form>input {
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
    width: 98%;
}

.register__registerButton {
    background: lightgrey;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: white;
}

.register__terms {
    padding-top: 20px;
    font-size: 0.7em;
}
.register__result{
    color: red;
}