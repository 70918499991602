.checkout{
    padding: 20px;
    background-color: white;
    height: max-content;
}
.checkout__ad{
    width: 100%;
    margin-bottom: 10px;
}
.checkout__title{
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
}
.checkout__right{
    float: right;
}
.checkout__left{
    float: left;
}
.subtotal{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    border-radius: 3px;
}
.subtotal > button{
    cursor: pointer;
    background-color: orange;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: orange;
    color: #111;
}
.checkoutProduct{
    border: 1px solid black;
    text-align: center;
    margin-top: 10px;
}
.checkoutProduct__title{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
}
.checkoutProduct__removeButton{
    cursor: pointer;
    background-color: orange;
    border-radius: 2px;
    width: auto;
    height: 30px;
    border: 1px solid;
    margin-top: 5px;
    margin-bottom: 10px;
    border-color: orange;
    color: #111;
}
.checkoutProduct__image {
    object-fit: contain;
    width: 110px;
    height: 180px;
}