.dashboard {
    display: flex;
    padding: 10px;
}

/*---------------------------------------------*/
.dashboard__sidemenu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 200px;
}

.dashboard__sidemenu>ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 200px;
}

.dashboard__sidemenu>ul>li {
    cursor: pointer;
    list-style-type: none;
    padding: 10px;
}

.dashboard__sidemenu>ul>li:hover {
    background-color: rgba(73, 228, 255, 0.178);
}

.dashboard__panel {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    margin-left: 10px;
    text-align: center;
    width: -webkit-fill-available;
}

/*---------------------------------------------*/

.myprofile__avatar {
    margin: auto;
    text-align: -webkit-center;
    margin-bottom: 30px;
}

.myprofile__avatarimage {
    width: 150px;
    height: 150px;
    margin: auto;
    border: 1px solid black;
}


/*---------------------------------------------*/

.addproduct__fileuploadwrapper {
    width: 600px;
    margin: 0 auto;
}

.addproduct__fileuploadinput {
    margin: 0;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

.addproduct__fileuploadinput:hover {
    background: #4212b1;
    color: #ffffff;
    transition: all .2s ease;
    cursor: pointer;
}

.addproduct__image {
    width: 120px;
    height: 120px;
    margin: 3px;
    object-fit: cover;
    display: inline-block;
}

.addproduct__keyword {
    font-size: smaller;
    display: inline-block;
    margin: 5px;
    padding: 3px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #1A233A;
    background-color: #bcd0f7;
}

.addproduct__previewtechnical {
    display: inline-table;
}

.addproduct__technical {
    display: table-row;
}

.addproduct__technical>div {
    display: inline-block;
    display: table-cell;
    border: 1px solid black;
}

.addproduct__previewkeywords {
    text-align: center;
}

.addproduct__spinner {
    margin: auto;
    text-align: -webkit-center;
}

/*-------------------------------------*/
.myproducts__excelbutton {
    background: #fff;
    border-radius: 0.375rem;
    border-style: solid;
    border-width: 0.125rem;
    box-sizing: border-box;
    color: #212121;
    cursor: pointer;
    display: inline-block;
    font-family: Circular, Helvetica, sans-serif;
    font-weight: 700;
    padding: 0.875rem 1.125rem;
    position: relative;
    text-align: left;
    text-decoration: none;
}

/*---------------------------------*/

.editproduct>button:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
}

.editproduct__fileuploadwrapper {
    color: blue;
    background-color: white;
}

.editproduct__fileuploadinput {
    margin: 0;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

.editproduct__fileuploadinput:hover {
    background: #4212b1;
    color: #ffffff;
    transition: all .2s ease;
    cursor: pointer;
}

.editproduct__previewimageswrap {
    position: relative;
    width: 120px;
    height: 120px;
    display: inline-block;
}

.editproduct__previewimagecover {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 100;
    padding: 5px;
    cursor: pointer;
}

.editproduct__previewimagecover:hover {
    color: white;
}

.editproduct__previewimagedelete {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    padding: 5px;
    cursor: pointer;
}

.editproduct__previewimagedelete:hover {
    color: white;
}

.editproduct__image {
    width: 120px;
    height: 120px;
    margin: 3px;
    object-fit: cover;
    display: inline-block;
}

.editproduct__keyword {
    font-size: smaller;
    display: inline-block;
    margin: 5px;
    padding: 3px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #1A233A;
    background-color: #bcd0f7;
}

.editproduct__previewtechnical {
    display: inline-table;
}

.editproduct__technical {
    display: table-row;
}

.editproduct__technical>div {
    display: inline-block;
    display: table-cell;
    border: 1px solid white;
}

.editproduct__previewkeywords {
    text-align: center;
}

.editproduct__spinner {
    margin: auto;
    text-align: -webkit-center;
}

/**********************/
.admin__navmenu {
    margin-bottom: 10px;
}

.admin__menuitem {
    display: inline-block;
    margin: 2px;
    color: #444;
    background: #FFD814;
    border-color: #FCD200;
    box-shadow: 0 0 5px -1px rgb(0 0 0 / 20%);
    cursor: pointer;
    vertical-align: middle;
    padding: 5px;
    text-align: center;
    border-radius: 3px;
}

.admin__menuitem:hover {
    background: #ffdf3e;
    color: white;
    font-weight: 500;
}

/*************************************/
.myprofile,
.addproduct,
.editproduct,
.myproducts,
.sales,
.listsales,
.menueditor,
.admin,
.manageproducts,
.subscriptions {
    background-color: rgba(233, 233, 233, 0.281);
}

.dashboard__title {
    font-size: large;
    color: rgba(0, 12, 122, 0.897);
    font-weight: 500;
    padding: 5px;
}

.dashboard__status {
    color: red;
    padding: 10px;
}

.dashboard__body>label {
    display: block;
    margin-top: 0.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    text-align: left;
}

.dashboard__body>input {
    border-radius: 2px;
    font-size: .825rem;
    background: #1A233A;
    color: #bcd0f7;
    border: 1px solid #596280;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-sizing: border-box;
}

.dashboard__body>input:hover {
    background-color: #283658;
}

.dashboard__body>select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dashboard__inputgroup>input {
    background: #1a233a;
    border: 1px solid #596280;
    border-radius: 2px;
    box-sizing: border-box;
    color: #bcd0f7;
    display: inline;
    font-size: .825rem;
    font-weight: 400;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 86%;
}

.dashboard__inputgroup>select {
    display: inline;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 14%;
}

.product__categoryoption1 {
    font-weight: 700;
    color: blue;
}

.product__categoryoption2 {
    color: black
}

.product__categoryoption3 {
    color: green;
    font-weight: 600;
}

.dashboard__body>textarea {
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
    height: auto;
    display: block;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    background: #1A233A;
    color: #bcd0f7;
    border: 1px solid #596280;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    overflow: hidden;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dashboard__body>textarea:hover {
    background-color: #283658;
}

.dashboard__body>button {
    cursor: pointer;
    background-color: orange;
    width: 90%;
    color: white;
    border: 0;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
}

.dashboard__table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.7em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    width: 100%;
    color: grey;
    table-layout: auto;
}

.dashboard__table thead tr {
    background-color: #b71919;
    color: #ffffff;
    text-align: center;
}

.dashboard__table th,
.dashboard__table td {
    padding: 12px 15px;
}

.dashboard__table tbody tr:hover {
    background-color: white;
}

.dashboard__table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.dashboard__table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.dashboard__table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.dashboard__table tbody tr td button {
    background-color: #b71919;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.dashboard__tablelink {
    cursor: pointer;
    color: #0069d9;
}

.dashboard__tablelink:hover {
    text-decoration: underline;
}

.dashboard__menuitemadmin {
    border-left: 3px solid red;
}

.dashboard__menutable {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.7em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    width: 100%;
    color: grey;
    table-layout: auto;
}