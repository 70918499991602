.menu {
    font-size: medium;
}

.menu__sidemenu {
    float: left;
    width: 30%;
}

.menu__firstcategorytitle {
    cursor: pointer;
    font-size: 1.2em;
    padding: 1px;
    font-weight: 600;
}

.menu__firstcategorytitle:hover {
    background-color: grey;
    color: white
}

.menu__secondcategorytitle {
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 200;
    margin-left: 6px;
}

.menu__secondcategorytitle:hover {
    background-color: grey;
    color: white
}

.menu__secondcategoryitems {
    display: none;
}

.menu__thirdcategorytitle {
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 200;
    margin-left: 15px;
}

.menu__thirdcategorytitle:hover {
    background-color: grey;
    color: white
}

.menu__thirdcategoryitems {
    display: none;
}

.show {
    display: block;
}

.menu__panel {
    float: right;
    width: 70%;
    padding-top: 10px;
}

.menu__panelbreadcrumb {
    background-color: rgb(0, 0, 0);
    color: white;
    padding: 10px;
    margin: 10px;
}

.menu__panelcontainer {
    margin: 7px;
}

.menu__panellisting {
    align-items: center;
    padding: 10px 30px 10px 10px;
    margin: 10px;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 5px 7px -1px rgb(51 51 51 / 23%);
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
}

.menu__panellistingcheckbox {
    float: right
}

.menu__panellistingleft {
    width: 70%;
    float: left;
}

.menu__panellistingright {
    width: 20%;
    float: right;
}

.menu__panelimage {
    width: 100%;
    height: 50px;
    object-fit: cover;
    object-position: 50% 50%;
}

@media (max-width: 800px) {
    .menu__firstcategory {
        font-size: small;
    }

    .menu__panelname {
        font-size: small;
    }
}

@media (max-width: 600px) {
    .menu__firstcategory {
        font-size: x-small;
    }

    .menu__panelname {
        font-size: x-small;
    }
}