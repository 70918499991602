.myproducts__downloadbutton {
    background: #fff;
    border-radius: 0.375rem;
    border-style: solid;
    border-width: 0.125rem;
    box-sizing: border-box;
    color: #212121;
    cursor: pointer;
    display: inline-block;
    font-family: Circular, Helvetica, sans-serif;
    font-weight: 700;
    padding: 0.875rem 1.125rem;
    position: relative;
    text-align: left;
    text-decoration: none;
}

.myproducts__title {
    color: rgb(188, 208, 247);
    display: block;
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
    text-align: center;
    text-size-adjust: 100%;
}

.myproducts__table {
    border-collapse: collapse;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    color: blue;
    font-size: smaller;
}

.myproducts__table thead {
    display: table-header-group;
    vertical-align: middle;
    color: black
}

.myproducts__table thead th {
    font-size: small;
    padding: 5px;
}

.myproducts__table thead tr {
    height: 60px;
    background: #FFED86;
    font-size: 16px;
}