/* Slideshow */

.slideshow {
    margin: 0 auto;
    width: 80%;
    overflow: hidden;
    margin-bottom:10px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
    width: 100%;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #6a0dad;
  }