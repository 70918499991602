@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');

.search {
    text-align: center;
    padding: 10px;
    font-family: 'Noto Sans', sans-serif;
}

.search__header {
    background-color: white;
    border-radius: 2px;
    text-align: left;
    font-size: small;
    font-weight: 500;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 0 10px #ddd;
    background-color: #fff;
    position: relative;
    line-height: 20px;
    margin: 5px;
    padding: 5px;
}

.search__container {
    display: flex;
}

.search__resulttitle {
    text-align: left;
    font-weight: 500;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.4;
}

.search__navtitle {
    line-height: 23px;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
}

.search__nav {
    background-color: lightyellow;
    padding: 20px;
    margin: 10px;
    height: 100%;
    flex: 0.2;
    border: 1px solid lightgrey;
}

.search__navcat {
    text-align: left;
}

.search__navcatitem {
    font-weight: 500;
    font-size: 15px;
    font-family: "Amazon Ember", Arial, sans-serif;
    line-height: 20px;
    cursor: pointer;
}

.search__navcatitem:hover {
    color: darkred
}

.search__navcatseemore {
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    cursor: pointer;
}

.search__result {
    height: 100%;
    flex: 0.8;
    padding: 20px;
}

.search__resultpanel {
    display: block;
    list-style-type: none;
    padding: 0;
}

.search__resultpanelnoresults {
    color: red;
    font-weight: 500;
}

.search__resultitem {
    align-items: center;
    border-radius: 3px;
    box-shadow: 0 5px 7px -1px rgb(51 51 51 / 23%);
    cursor: pointer;
    overflow: hidden;
}

.search__resultitem:hover {
    background-color: lightgoldenrodyellow;
}

.search__resultitemimagecontainer {
    float: left;
    width: 30%;
}

.search__resultitemimage {
    width: 100px;
    height: 100px;
    padding: 8px 16px;
}

.search__resultitemname {
    float: left;
    width: 50%;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    margin-top: 1.4em;
}

.search__resultitemprice {
    float: left;
    width: 20%;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    margin-top: 1.4em;
}

@media (max-width: 600px) {
    .search__resultitemimage {
        display: none !important;
    }

    .search__resultitemname {
        width: 80%;
    }
}

@media (max-width: 450px) {
    .search__resultitemimage {
        display: none !important;
    }

    .search__resultitemname {
        width: 80%;
        font-size: 10px;
    }
    .search__resultitemprice {
        font-size: 11px;
    }
}