.productcategory__title{
    text-align: center;
    padding: 20px;
    font-size: 1.6em;
    font-weight: bold;
}
.productcategory__row{
    display: flex;
    z-index: 1;
    margin-left: 5px;
    margin-right: 5px;
}