.product {
    display: inline-flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    margin: 10px;
    padding: 20px;
    width: 20%;
    min-width: 100px;
    max-width: 30%;
    background-color: white;
    border: 1px solid #f0f0f0;
}

.product:hover {
    background-color: lightgoldenrodyellow;
}

.product__imagewrapper {
    align-items: center;
    width: 100%;
    height: 150px;
}

.product__image {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 167px;
    object-fit: cover;
}

.product__titlewrapper {
    margin-top: 12px;
    font-size: 12px;
    overflow: hidden;
    word-wrap: break-word;
    height: 40px;
    margin-bottom: 12px;
}

.product__title {
    line-height: 20px;
    color: #333;
    letter-spacing: 0;
    font-size: 12px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    text-decoration: none;
}

.product__title:hover {
    text-decoration: underline;
    color: red
}

.product__price {
    margin-top: 15px;
    text-align: center;
}

.product__addtocart {
    background: orange;
    border: 1px solid;
    margin-top: 10px;
    color: black;
}

.product__addtocart:hover {
    background-color: white;
    cursor: pointer;
}

p {
    text-decoration: none;
    color: black;
}

.product__percent {
    font-size: 0.7em;
    padding-top: 5px;
}