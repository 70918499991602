.navmenu {
	background-color: orange;
	margin: 3px;
	border-radius: 3px;
	text-align: left;
}

.navmenu:hover {
	background-color: rgb(255, 172, 17);
}

.navmenu__button {
    background-color: #000;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    margin: 3px;
    padding: 11px 32px;
    text-align: center;
    transition-duration: .4s;
}

.navmenu__button:hover {
	background-color: #9c9c9c4f;
	color: white;
	box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0;
}

.invert {
	background-color: white;
	color: black;
}