.productinfo{
    text-align: center;
    margin: auto;
    padding: 20px;
}
.productinfo__id{
    font-size: 0.9em;
    font-weight: 300;
    margin-bottom: 20px;
}
.productinfo__name{
    font-weight: bold;
    font-size: 1.8em;
}
.productinfo__description{
    text-align: left;
    border: 1px solid grey;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    white-space:pre-line;
    font-size: 0.8em;
    font-size: 14px;
    line-height: 20px;
    -webkit-text-size-adjust: 100%;
}
.productinfo__supplier{
    text-align: left;
    border: 1px solid grey;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    white-space:pre-line;
    font-size: 0.8em;
    font-size: 14px;
    line-height: 20px;
    -webkit-text-size-adjust: 100%;
}
.productinfo__discussionsection{
    text-align: left;
    border: 1px solid grey;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    white-space:pre-line;
    font-size: 0.8em;
    font-size: 14px;
    line-height: 20px;
    -webkit-text-size-adjust: 100%;
}
.productinfo__technical{
    text-align: left;
    border: 1px solid grey;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    white-space:pre-line;
    font-size: 0.8em;
    font-size: 14px;
    line-height: 20px;
    -webkit-text-size-adjust: 100%;
}
.productinfo__condition{
    text-align: left;
    border: 1px solid grey;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    white-space:pre-line;
    font-size: 0.8em;
    font-size: 14px;
    line-height: 20px;
    -webkit-text-size-adjust: 100%;
}
.productinfo__price{
    text-align: left;
    border: 1px solid grey;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    white-space:pre-line;
    font-size: 0.8em;
    font-size: 14px;
    line-height: 20px;
    -webkit-text-size-adjust: 100%;
}
.productinfo__allocation{
    text-align: left;
    border: 1px solid grey;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    white-space:pre-line;
    font-size: 0.8em;
    font-size: 14px;
    line-height: 20px;
    -webkit-text-size-adjust: 100%;
}
.productinfo__technicaltable{
    border: 1px solid black;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    text-align: center;
}
.productinfo__technicaltable>tbody>tr>td{
    border: 1px solid black;
}
.productinfo__technicaltable>tbody>tr:hover{
    background-color: orange;
    color: black;
    font-weight: bold;
}

.productinfo__image{
    object-fit: contain;
}
.slide>div>img{
    height:300px;
}

.spinner{
    padding-top: 50px;
    margin: auto;
    text-align: -webkit-center;
}