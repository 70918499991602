.plans{
    text-align: center;
    padding-top: 30px;
    background: #ffffff;
}
.plans__title{
    font-family: /* Roman */ Source Sans Pro, Helvetica, Arial, sans-serif, /* CJK */ Hiragino Kaku Gothic Pro, Meiryo, Hiragino Sans GB W3, /* Arabic */ Noto Naskh Arabic, Droid Arabic Naskh, Geeza Pro, Simplified Arabic, /* Thai */ Noto Sans Thai, Thonburi, Dokchampa, Droid Sans Thai, /* Sans Fallbacks */ Droid Sans Fallback, -apple-system, '.SFNSDisplay-Regular', /* CJK Fallbacks */ Heiti SC, Microsoft Yahei, Segoe UI;
    line-height: 48px;
    font-weight: 200;
    color: rgba(0,0,0,0.85);
    font-size: 42px;
    margin: -8px 0 8px;
}
.plans__caption{
    font-family: /* Roman */ Source Sans Pro, Helvetica, Arial, sans-serif, /* CJK */ Hiragino Kaku Gothic Pro, Meiryo, Hiragino Sans GB W3, /* Arabic */ Noto Naskh Arabic, Droid Arabic Naskh, Geeza Pro, Simplified Arabic, /* Thai */ Noto Sans Thai, Thonburi, Dokchampa, Droid Sans Thai, /* Sans Fallbacks */ Droid Sans Fallback, -apple-system, '.SFNSDisplay-Regular', /* CJK Fallbacks */ Heiti SC, Microsoft Yahei, Segoe UI;
    line-height: 32px;
    font-weight: 200;
    color: rgba(0,0,0,0.85);
    font-size: 26px;
}
.plans__buttons{
    display: flex;
    height: 60px;
    background-color: white;
    color: darkgray;
    padding: 20px;
}
.plans__button{
    flex: 1;
    border: 1px solid grey;
    cursor: pointer;
}
.plans__button:hover{
    color: black;
    font-weight: 700;
}
.plans__buttonselected{
    border-bottom: 5px solid rgb(129, 129, 250)
}
.plans__columnbutton{
    background-color: antiquewhite;
    font-weight: 600;
}
.plans__buttontext{
    display:block;
    padding-top: 7px;
    font-weight: 700;
    font-size: 1.1em;
    color: rgb(70, 70, 70);
}
.plans__columns{
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 20px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.plans__column{
    background-color: #f5f4f4;
    flex: 10%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    padding: 13px;
    text-align: center;
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 300px;
    cursor: pointer;
}
.plans__column:hover{
    background-color: #ffce85;
}
.plans__columntitle{
    font-weight: 600;
    font-size: larger;
    border-bottom: 2px solid rgb(17, 17, 17)
}
.plans__columnlogo{
    width: 150px;
    height: 150px;
    margin: auto;
}
.plans__columncost{
    font-weight: 100;
    font-size: large;
}
.plans__comparison{
    padding: 20px;
}
.plans__comparisontabletitle{
    color: rgba(0,0,0,0.85);
    font-weight: 200;
    font-size: 42px;
    margin-bottom: 10px;
}
.plans__comparisontable{
    border: 1px solid;
    color: rgba(0,0,0,0.85);
    width: 100%;
}
.plans__comparisontable>tbody>tr:hover{
    background-color: #fce2ba;
    cursor: pointer;
}