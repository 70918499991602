.header {
    height: 80px;
    display: flex;
    align-items: center;
    background-color: black;
    top: 0;
    z-index: 100;
}

.header__logocontainer {
    padding: 5px;
    padding-left: 10px;
}

.header__logo {
    margin-top: 5px;
    width: 120px;
}

.header__delivery {
    display: flex;
    align-items: center;
    margin-right: 15px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    padding: 6px;
    border-color: #131921;
    height: auto;
}

.header__deliverymodal {
    text-align: center;
    padding: 20px;
}

.header__deliverymodal>button {
    background-color: orange;
    border-radius: 2px;
    width: 60%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    margin-bottom: 3px;
    border-color: orange;
    color: #111;
}

.header__deliverymodal__country {
    display: block;
    padding: 3px;
    width: 85%;
    margin: auto;
    margin-top: 10px;
}

.header__deliverymodal__address {
    display: block;
    padding: 3px;
    width: 85%;
    margin: auto;
    margin-top: 10px;
}

.header__deliverymodal__contactnumber {
    display: block;
    padding: 3px;
    width: 85%;
    margin: auto;
    margin-top: 10px;
}

.header__delivery:hover {
    border-color: white;
}

.header__deliveryLogo {
    color: rgb(236, 234, 234);
}

.header__deliveryText {
    padding-left: 5px;
}

.header__deliveryTextLocation {
    color: rgb(236, 233, 233);
}

.header__deliveryTextName {
    color: rgb(150, 149, 149);
}

.header__search {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 24px;
    padding-right: 10px;
    padding-left: 10px;
}

.header__searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header_searchIcon {
    padding: 5px;
    height: 22px !important;
    background-color: orange;
}

.header__nav {
    display: flex;
    justify-content: space-evenly;
}

.header__navOption {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    cursor: pointer;
}

.header__navOptionLine1 {
    font-size: 10px;
}

.header__navOptionLine2 {
    font-size: 13px;
    font-weight: 800;
}

.header__navBasket {
    display: flex;
    align-items: center;
    color: white;
}

.header__navBasketCount {
    color: white;
    margin-right: 10px;
    margin-left: 10px;
}