@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');

.home {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    font-family: 'Noto Sans', sans-serif;
}

.home__image {
    width: 100%;
    z-index: -1;
    position: relative;
    margin-bottom: -150px;
    mask-image: linear-gradient(to bottom, orange, orange);
}

.home__row {
    z-index: 1;
    margin-left: 5px;
    margin-right: 5px;
}

.home__rowscolumns {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.home__rowscolumns::-webkit-scrollbar {
    display: none;
}

.home__rowtitle {
    background-color: black;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    padding-top: 1px;
    padding-bottom: 4px;
}

.home__rowtitle:hover {
    cursor: pointer;
    background-color: orange;
}

.slide {
    width: 25%;
}